import React, {Component} from 'react';
import { Badge, ProgressBar, Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { L10n, getValue } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { getObject } from '@syncfusion/ej2-grids';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, ColumnChooser, ColumnMenu, ContextMenu, Edit, Toolbar } from '@syncfusion/ej2-react-treegrid';
import { Inject, Aggregate, Filter, Freeze, Sort, Reorder, Resize, InfiniteScroll, VirtualScroll, Page, ExcelExport } from '@syncfusion/ej2-react-treegrid';
import { AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective } from '@syncfusion/ej2-react-treegrid';
import QRCode from "react-qr-code";
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import PopoverColumnActions from './PopoverColumnActions';
import PopupInformation from './PopupInformation';
import Timeline from './Timeline';

const API_save_holidays = '/WebAppService/SaveHolidayModifications';

class TableTimeTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      formatDate : null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      currentView: {},
      dictParameters: {},
      columns: [],
      dictColumns: {},
      rows: [],
      dataSource: [],
      week: {},
      month: null,
      year: null,
      info: false,
      informationMessage: null,
      refreshCount: 0,
      refreshInProgress: false,
      error: {}
    };

    this.tableClicked = false;

    // Table Structure
    this.dataStructure = this.dataStructure.bind(this);
    this.getCurrentViewParameters = this.getCurrentViewParameters.bind(this);
    this.getColumnParameters = this.getColumnParameters.bind(this);

    // Fonctions
    this.formatDate = this.formatDate.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
    this.getColumnEditable = this.getColumnEditable.bind(this);
    this.getColumnEditionItemType = this.getColumnEditionItemType.bind(this);
    this.getColumnFilter = this.getColumnFilter.bind(this);
    this.getColumnLabel = this.getColumnLabel.bind(this);
    this.getColumnName = this.getColumnName.bind(this);
    this.getColumnAllowEditing = this.getColumnAllowEditing.bind(this);
    // this.getColumnRestrictedValues = this.getColumnRestrictedValues.bind(this);
    this.getColumnSort = this.getColumnSort.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    // this.getColumnUnit = this.getColumnUnit.bind(this);
    this.getCurrentViewLevel = this.getCurrentViewLevel.bind(this);
    this.getColumnsInPeriod = this.getColumnsInPeriod.bind(this);
    this.getModificationValue = this.getModificationValue.bind(this);
    this.getRowIndex = this.getRowIndex.bind(this);
    this.getRowItemId = this.getRowItemId.bind(this);
    this.getTreeSearchSettings = this.getTreeSearchSettings.bind(this);

    // Syncfusion Events
    this.dataBound = this.dataBound.bind(this);
    this.rowDataBound = this.rowDataBound.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.cellEdit = this.cellEdit.bind(this);

    // Actions
    this.refreshComponent = this.refreshComponent.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.refreshDatasource = this.refreshDatasource.bind(this);
    this.createColumn = this.createColumn.bind(this);

    this.filterColumns = this.filterColumns.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.sortColumns = this.sortColumns.bind(this);
    this.autofitColumn = this.autofitColumn.bind(this);
    this.autofitAllColumns = this.autofitAllColumns.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.onHolidayClick = this.onHolidayClick.bind(this);
    this.onHolidayDoubleClick = this.onHolidayDoubleClick.bind(this);
    this.updateHolidays = this.updateHolidays.bind(this);
    this.saveHolidays = this.saveHolidays.bind(this);
    this.updateHolidaysDatasource = this.updateHolidaysDatasource.bind(this);
    this.updateDatasource = this.updateDatasource.bind(this);
    this.updateDictModifications = this.updateDictModifications.bind(this);
    this.updateGridCell = this.updateGridCell.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
    this.excelQueryCellInfo = this.excelQueryCellInfo.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
    this.updateErrors = this.updateErrors.bind(this);

    // Template Header
    this.templateHeader = this.templateHeader.bind(this);
    // Template Columns
    this.templateColumnHeaderDay = this.templateColumnHeaderDay.bind(this);
    this.templateColumnDay = this.templateColumnDay.bind(this);
    this.templateColumnBankHoliday = this.templateColumnBankHoliday.bind(this);
    this.templateColumnWeekend = this.templateColumnWeekend.bind(this);
    this.templateColumnAction = this.templateColumnAction.bind(this);
    this.templateColumnBusinessLine = this.templateColumnBusinessLine.bind(this);
    this.templateColumnImpact = this.templateColumnImpact.bind(this);
    this.templateColumnName = this.templateColumnName.bind(this);
    this.templateColumnMeteo = this.templateColumnMeteo.bind(this);
    this.templateColumnMonthBurned = this.templateColumnMonthBurned.bind(this);
    this.templateColumnPriority = this.templateColumnPriority.bind(this);
    this.templateColumnProbability = this.templateColumnProbability.bind(this);
    this.templateColumnProgress = this.templateColumnProgress.bind(this);
    this.templateColumnProject = this.templateColumnProject.bind(this);
    this.templateColumnStatus = this.templateColumnStatus.bind(this);
    this.templateColumnTimeline = this.templateColumnTimeline.bind(this);
    this.templateColumnTrend = this.templateColumnTrend.bind(this);
    this.templateColumnWorkpackage = this.templateColumnWorkpackage.bind(this);
    // Template Types
    this.templateTypeAxe = this.templateTypeAxe.bind(this);
    this.templateTypeAxisTable = this.templateTypeAxisTable.bind(this);
    this.templateTypeBoolean = this.templateTypeBoolean.bind(this);
    this.templateTypeDate = this.templateTypeDate.bind(this);
    this.templateTypeDouble = this.templateTypeDouble.bind(this);
    this.templateTypeHTML = this.templateTypeHTML.bind(this);
    this.templateTypeLink = this.templateTypeLink.bind(this);
    this.templateTypeLocation = this.templateTypeLocation.bind(this);
    this.templateTypePercentage = this.templateTypePercentage.bind(this);
    this.templateTypeProgress = this.templateTypeProgress.bind(this);
    this.templateTypeQRCode = this.templateTypeQRCode.bind(this);
    this.templateTypeRating = this.templateTypeRating.bind(this);
    this.templateTypeResource = this.templateTypeResource.bind(this);
    this.templateTypeResourceTable = this.templateTypeResourceTable.bind(this);
    this.templateTypeText = this.templateTypeText.bind(this);
    // Aggregate Functions
    this.customAggregate = this.customAggregate.bind(this);
    this.footerTemplateOpenDay = this.footerTemplateOpenDay.bind(this);
    this.footerTemplateWeekend = this.footerTemplateWeekend.bind(this);

    this.errors = React.createRef();
  }
  
  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const week = this.props.Week;
    const month = this.props.Month;
    const year = this.props.Year;

    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Planning Datasource
    const dataSource = this.dataStructure(rows);

    this.setState({ login, authId, language, formatDate, itemId, itemType, itemTitle, blockType, currentView, dictParameters, dictColumns, columns, rows, dataSource, week, month, year, refreshCount: 1 });
  }

  componentDidUpdate(prevProps) {
    const month = this.props.Month;
    const year = this.props.Year;

    if(this.props.Month !== prevProps.Month || this.props.Year !== prevProps.Year) {
      this.setState({ month, year });
    }
  }

  // shouldComponentUpdate(prevProps, prevState) {
  //   if(this.state.refreshCount !== prevState.refreshCount) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Add the Column Name/Values to the reduced Table
          // Format Day
          if(item.FieldType === 'Day' && item.Value && item.Value !== 'Holiday') {
            if(item.ColumnName === 'Name' || item.ColumnName === 'Item_Type') {
              acc[item.ColumnName] = item.Value;
            }
            else {
              const date = new Date(item.ColumnName);
              const columnName = "field_" + date.getDate();

              acc[columnName] = parseFloat(item.Value);
            }
          }
          // Format Dates
          else if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Meteo/Trend
          else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Object Tables (Decisions/Files/HashTags/Rights/Resources)
          else if(item.FieldType === 'ObjectTable' || item.FieldType === 'Files' || item.FieldType === 'ResourceTable') {
            if(item.Value) {
              acc[item.ColumnName] = JSON.parse(item.Value);
            }
            else {
              acc[item.ColumnName] = [];
            }
          }
          // Exclude Parent_ID for Table
          else if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }
          
          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  // Build Current View Parameters
  getCurrentViewParameters(currentView) {
    let dictParameters = {};

    // Default values
    dictParameters['Columns'] = [];
    dictParameters['ColumnsWidth'] = [];

    for(let i=0; i < currentView.Parameters.length; i++) {
      // Columns
      if(currentView.Parameters[i].Name === 'Columns') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value.split(',');
        }
      }
      // Columns Width
      else if(currentView.Parameters[i].Name === 'ColumnsWidth') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = JSON.parse(currentView.Parameters[i].Value);
        }
      }
      else {
        dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value;
      }
    }

    return dictParameters;
  }

  // Build Current View Columns
  getColumnParameters(columnHeaders) {
    let dictColumns = {};

    for(let i=0; i < columnHeaders.length; i++) {
      if(columnHeaders[i]) {
        dictColumns[columnHeaders[i].FieldName] = columnHeaders[i];
      }
    }

    return dictColumns;
  }

  // Format Date for Timeline calculation
  formatDate(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0]-1; // Between 0 and 11
      const year = split[2];

      formattedDate = new Date(Date.UTC(year, month, day)).toISOString();
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  getColumnEditable(editionItemTypes) {
    const currentView = this.state.currentView;

    // Get View corresponding level
    const level = this.getCurrentViewLevel(currentView);

    if(editionItemTypes.includes(level)) {
      return true;
    }
    else {
      return false;
    }
  }

  getColumnEditionItemType(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.EditionItemTypes;
    }
    else {
      return "";
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).EditionItemTypes;
    // }
    // else {
    //   return false;
    // }
  }

  getColumnFilter(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Filters && currentView.Filters.length > 0) {
      if(currentView.Filters.find(filter => filter.Field === columnName)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  getColumnLabel(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];
    let label = '';

    if(columnName === 'WarningMessage') {
      label = 'W';
    }
    else if(column) {
      label = column.Label;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   label = columns.find(column => column.FieldName === columnName).Label;
    // }
    // else if(columnName === 'WarningMessage') {
    //   label = 'W';
    // }
    
    return label;
  }

  getColumnName(props) {
    const dictParameters = this.state.dictParameters;
    let columnName = '';

    // Get Column Name
    if(!props || !props.column) {
      columnName = dictParameters['Columns'][0];
    }
    else {
      columnName = props.column.field;
    }

    return columnName.replace(".Label", "");
  }

  getColumnAllowEditing(props) {
    const { dictParameters, dictColumns } = this.state;
    let column, columnName, allowEditing;

    // Get Column Name
    if(!props || !props.column) {
      columnName = dictParameters['Columns'][0];
      column = dictColumns[columnName];

      if(column && column.EditionItemTypes.includes(props.Item_Type)) {
        allowEditing = true;
      }
      else {
        allowEditing = false;
      }
    }
    else {
      allowEditing = props.column.allowEditing;
    }

    return allowEditing;
  }

  getColumnRestrictedValues(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.RestrictedValues;
    }
    else {
      return [];
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).RestrictedValues;
    // }
    // else {
    //   return [];
    // }
  }

  getColumnSort(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " DESC")) {
        return "descending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Descending")) {
        return "descending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName)) {
        return "ascending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Ascending")) {
        return "ascending";
      }
      else {
        return false;
      }
      
      // if(currentView.Parameters.find(param => param.Name === 'Sort').Value.includes(columnName + " DESC")) {
      //   return "descending";
      // }
      // else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.includes(columnName)) {
      //   return "ascending";
      // }
    }
    else {
      return false;
    }
  }

  getColumnType(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.FieldType;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).FieldType;
    // }
    // else {
    //   return null;
    // }
  }

  getColumnUnit(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.Unit;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).Unit;
    // }
    // else {
    //   return null;
    // }
  }

  getCurrentViewLevel() {
    const currentView = this.state.currentView;
    let level;

    // Get View corresponding level
    if(currentView) {
      switch(currentView.DefaultLevel) {
        case 0:
            level = 'Tree';
            break;
        case 1:
            level = 'Business_Line';
            break;
        case 2:
            level = 'Project';
            break;
        case 3:
            level = 'Workpackage';
            break;
        case 4:
            level = 'Action';
            break;
        case 5:
            level = 'Task';
            break;
        case 6:
            level = 'Risk';
            break;
        case 7:
            level = 'Issue';
            break;
        case 8:
            level = 'Decision';
            break;
        case 9:
            level = 'Meeting';
            break;
        case 10:
            level = 'Definition';
            // level = 'Budget';
            break;
        case 11:
            level = 'Link';
            break;
        case 12:
            level = 'Resource';
            break;
        case 13:
            level = 'Entity';
            break;
        case 14:
            level = 'User';
            break;
        case 15:
            level = 'AxisValue';
            break;
        case 16:
            level = 'DeletedItem';
            break;
        case 18:
            level = 'Workload';
            break;
        case 19:
            level = 'CustomField';
            break;
        case 20:
            level = 'HashTag';
            break;
        case 21:
            level = 'BudgetCode';
            break;
        case 22:
            level = 'UserGroup';
            break;
        case 23:
            level = 'Warning';
            break;
        case 24:
            level = 'Sprint';
            break;
        case 25:
            level = 'AgileBoard';
            break;
        case 26:
            level = 'Dependency';
            break;
        case 30:
            level = 'NotificationRule';
            break;
        default:
            break;
      }
    }

    return level;
  }

  getColumnsInPeriod(column) {
    const startPeriod = this.state.startPeriod;
    const endPeriod = this.state.endPeriod;
    let date, start, end;

    if(column.FieldType === 'OpenDay' || column.FieldType === 'BankHoliday' || column.FieldType === 'Weekend') {
      date = new Date(this.formatDate(column.FieldName));
      start = new Date(this.formatDate(startPeriod));
      end = new Date(this.formatDate(endPeriod));

      // Set the same Hours/Minutes/Seconds/Milliseconds to compare Dates
      date.setHours(0,0,0,0);
      start.setHours(0,0,0,0);
      end.setHours(0,0,0,0);

      // If Date is after the Period or Date is before the Period
      if((date.getTime() > end.getTime()) || (start.getTime() > date.getTime())) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  }

  getModificationValue(columnName, newValue) {
    let value;

    // Get Column Type
    const columnType = this.getColumnType(columnName);
      
    // Parse Object for Axes
    if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Resource' || columnType === 'ResourceTable' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity') {
      value = JSON.parse(newValue);
    }
    // Parse Double
    else if(columnType === 'Double') {
      value = parseFloat(newValue);
    }
    // Parse Date
    else if(columnType === 'Date') {
      if(newValue) {
        value = new Date(newValue);
      }
      else {
        value = "";
      }
    }
    else {
      value = newValue;
    }

    return value;
  }

  getRowIndex(modificationId) {
    let index = -1;

    if(this.grid && this.grid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
      // Get Row index
      index = this.grid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
      // index = this.grid.dataSource.findIndex(row => row['Item_ID'] === modificationId);
    }

    return index;
  }

  getRowItemId(itemType, itemId) {
    return itemType.substring(0,1).concat(itemId);
  }

  getTreeSearchSettings(dictColumns, columns) {
    let fields = [];

    for(let i = 0; i < columns.length; i++) {
      if(dictColumns[columns[i]] && (dictColumns[columns[i]].FieldType === 'Axe' || dictColumns[columns[i]].FieldType === 'AxisTable' || dictColumns[columns[i]].FieldType === 'Resource' || dictColumns[columns[i]].FieldType === 'ResourceTable' || dictColumns[columns[i]].FieldType === 'Location' || dictColumns[columns[i]].FieldName === 'Meteo' || dictColumns[columns[i]].FieldName === 'Trend' || dictColumns[columns[i]].FieldName === 'Business_Line' || dictColumns[columns[i]].FieldName === 'Project' || dictColumns[columns[i]].FieldName === 'Workpackage' || dictColumns[columns[i]].FieldName === 'Action' || dictColumns[columns[i]].FieldName === 'Task' || dictColumns[columns[i]].FieldName === 'Entity')) {
        fields.push(dictColumns[columns[i]].FieldName + ".Label");
      }
      else if(dictColumns[columns[i]]) {
        fields.push(dictColumns[columns[i]].FieldName);
      }
    }

    return { fields: fields };
  }

  dataBound(args) {
    if(this.grid) {
      // Table Event Listener
      if(!this.tableClicked) {
        this.grid.element.addEventListener('click', this.onHolidayClick);
        this.grid.element.addEventListener('dblclick', this.onHolidayDoubleClick);
        this.tableClicked = true;
      }
    }
  }

  // Row Formatting design
  rowDataBound(args) {
    // Define styles for Objects rows
    if(getObject('Item_Type', args.data) === 'Task') {
      args.rowHeight = 50;
      args.row.style.fontWeight = '100';
    }
  }

  actionBegin(args) {
    const { refreshInProgress } = this.state;

    // Refresh
    if(args.requestType === 'refresh') {
      if(refreshInProgress) {
        args.cancel = true;
      }
      else {
        args.cancel = false;
      }
    }
    // Search
    else if(args.requestType === 'searching') {
      if(args.searchString || args.searchString === '') {
        args.cancel = false;
      }
      else {
        args.cancel = true;
      }
    }
  }

  actionComplete(args) {
    const { language, currentView, month, year } = this.state;

    // Columns
    if(args.requestType === 'columnstate' || args.requestType === 'reorder') {
      let columnNames = '', columns = [];

      this.grid.columns.forEach((column, index) => {
        if(column.visible && !column.field.includes("field")) {
          // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
          column.field = column.field.replace(".Label", "");

          columns.push(column.field);
        }
      });

      columnNames = columns.join(',');

      // Update Current View Columns list
      // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      //   currentView.Parameters.find(param => param.Name === 'Columns').Value = columnNames;
      // }

      // Add Modify attribute in CurrentView
      // currentView["Modify"] = true;

      // Call the event from the Parent component through the props with view value
      // this.props.onViewChange(currentView);

      // Call the event from the Parent component through the props with view value
      this.props.onColumnReorder(columnNames);
    }
    // Save
    else if(args.type == 'save') {
      if(args.data) {
        const itemId = args.data.Item_ID.substring(1);
        const itemType = args.data.Item_Type;
        const day = args.column.field.replace("field_", "");
        const date = month + "/" + day + "/" + year;
        let oldValue = args.previousData;
        let newValue = args.data[args.column.field];
        // const warning = args.data['WarningMessage'];
        let columnName;

        // if(itemType === 'Holiday') {
        //   this.grid.closeEdit();
        //   this.grid.endEdit();
        // }

        // Table Modification on Task Day_Burned
        if(itemType === 'Task') {
          columnName = 'Day_Burned';
        }

        if(newValue === null) {
          // Replace null value by 0 in Grid dataSource
          if(this.grid.parentData.find(row => row.Item_ID === itemType.substring(0, 1).concat(itemId))) {
            this.grid.parentData.find(row => row.Item_ID === itemType.substring(0, 1).concat(itemId))[args.column.field] = "0";
          }
          if(this.grid.dataSource.find(row => row.Item_ID === itemType.substring(0, 1).concat(itemId))) {
            this.grid.dataSource.find(row => row.Item_ID === itemType.substring(0, 1).concat(itemId))[args.column.field] = "0";
          }

          // If newValue is null change it to 0
          newValue = 0;

          // const day = new Date(date).getDate();
          // const month = new Date(date).getMonth() + 1;
          // const year = new Date(date).getFullYear();

          // let additionalContext = [{"Key": "Day", "Value": day}, {"Key": "Month", "Value": month}, {"Key": "Year", "Value": year}];

          // // Cancel the last modification
          // let modification = { AdditionalContext: additionalContext, ItemId: itemId, ItemType: itemType, ColumnName: date, OldValue: "0" };
          
          // this.cancelModification(modification);
        }

        if(oldValue === null) {
          const day = new Date(date).getDate();
          const month = new Date(date).getMonth() + 1;
          const year = new Date(date).getFullYear();

          let additionalContext = [{ Key: "Day", Value: day }, { Key: "Month", Value: month }, { Key: "Year", Value: year }];

          // Display Popup with Error message
          this.setState({ info: true, informationMessage: Traduction.translate(language, 'timetracking_impossible') });

          // Cancel the last modification
          let modification = { AdditionalContext: additionalContext, ItemId: itemId, ItemType: itemType, ColumnName: date, OldValue: oldValue };
          
          this.cancelModification(modification);
        }

        // Check if oldValue is not null and different than the old value
        if(oldValue !== null && newValue != oldValue) {
          // Call the event from the Parent component through the props
          this.props.onTableUpdate(itemId, itemType, columnName, date, oldValue, newValue);
        }
      }
    }
  }

  cellEdit(args) {
    const columnName = args.columnName.replace(".Label", "");

    // Get Column Type
    const columnType = this.getColumnType(columnName);

    // Edit value with Popover
    if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Location' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity' || columnName === 'Levels') {
      // Cancel Editing in Table
      args.cancel = true;
    }
    // Edit value with Boolean icon
    else if(columnType === 'Boolean') {
      // Cancel Editing in Table
      args.cancel = true;
    }
    // Edit value with Rating icon
    else if(columnType === 'Rating') {
      // Cancel Editing in Table
      args.cancel = true;
    }
  }

  // Refresh Table Component
  refreshComponent(blockType, currentView, columns, rows) {
    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Table Datasource
    const dataSource = this.dataStructure(rows);

    this.setState({ currentView, dictParameters, dictColumns, rows, dataSource, refreshInProgress: true });

    this.setState({ refreshInProgress: false });

    // Refresh Table Datasource
    this.refreshDatasource(dataSource);

    // Refresh Table Columns
    this.refreshColumns(blockType, currentView, dictParameters, columns, dictColumns);

    this.grid.refreshColumns();
  }

  refreshColumns(blockType, currentView, dictParameters, columns, dictColumns) {
    let gridColumns = [], countOpenDay = 0;

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns
      // this.grid.columns = [];
      // this.grid.aggregates[0].columns = [];

      // Clean Search Settings
      // this.grid.searchSettings = { search: [] , operators: {} };

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < dictParameters['Columns'].length; i++) {
        let columnName = dictParameters['Columns'][i];

        if(dictColumns[columnName]) {
          // Create Column object
          const colObj = this.createColumn(dictColumns[columnName], dictParameters['ColumnsWidth'], true);

          // Add the Column in the Grid
          gridColumns.push(colObj);
          // this.grid.columns.push(colObj);
        }
      }

      // Loop through the columns days
      for(let i=0; i < columns.length; i++) {
        let columnName = columns[i].FieldName;
        let aggregate, footerTemplate;

        if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          const date = new Date(columnName);
          const day = date.getDate();

          // Create Column object
          const colObj = this.createColumn(dictColumns[columnName], dictParameters['ColumnsWidth'], true);

          // Add the Column in the Grid
          gridColumns.push(colObj);
          // this.grid.columns.push(colObj);

          // Get Column Aggregate
          aggregate = this.grid.aggregates[0].columns[countOpenDay];

          // Footer Template
          if(dictColumns[columnName].FieldType === 'OpenDay') {
            footerTemplate = this.footerTemplateOpenDay;
          }
          else if(dictColumns[columnName].FieldType === 'BankHoliday' || dictColumns[columnName].FieldType === 'Weekend') {
            footerTemplate = this.footerTemplateWeekend;
          }

          // var aggregate = new AggregateColumnDirective();

          // Update Column Aggregate
          aggregate.columnName = "field_" + day;
          aggregate.field = "field_" + day;
          aggregate.footerTemplate = footerTemplate;

          // aggregate.customAggregate = this.customAggregate;
          // aggregate.type = 'Custom';

          // Increment Count Open Day
          countOpenDay ++;

          // this.grid.aggregates[0].columns.push(aggregate);
        }
      }

      // Add Column Burned_Workload
      if(!dictParameters['Columns'].includes('Burned_Workload')) {
        let columnHeader = dictColumns['Burned_Workload'];

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        gridColumns.push(colObj);
        // this.grid.columns.push(colObj);
      }
      // Add Column Item_ID
      if(!dictParameters['Columns'].includes('Item_ID')) {
        let columnHeader = dictColumns['Item_ID'];

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          allowEditing: false,
          isPrimaryKey: true
        };

        gridColumns.push(colObj);
        // this.grid.columns.push(colObj);
      }
      // Add Column Item_Type
      if(!dictParameters['Columns'].includes('Item_Type')) {
        let columnHeader = dictColumns['Item_Type'];

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        gridColumns.push(colObj);
        // this.grid.columns.push(colObj);
      }

      // Update Table Columns
      this.grid.columns = gridColumns;

      // Update Grid Frozen Column
      // this.grid.frozenColumns = 1;

      // Update Grid Search Settings
      this.grid.searchSettings = this.getTreeSearchSettings(dictColumns, dictParameters['Columns']);
    }
  }

  refreshDatasource(dataSource) {
    if(this.grid) {
      this.grid.dataSource = dataSource;
    }
  }

  createColumn(columnHeader, columnsWidth, visible) {
    const { formatDate, itemType, blockType } = this.state;
    let headerTemplate, headerTextAlign, columnMenu, type, field, format, primaryKey, freeze, edit, editType, reorder, resize, sort, validationRules, template, width, minWidth, maxWidth;

    // Header
    if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      headerTemplate = this.templateColumnHeaderDay;
    }
    else {
      headerTemplate = this.templateHeader;
    }

    // Header Text Align
    if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      headerTextAlign = 'center';
    }
    else {
      headerTextAlign = 'left';
    }

    // Column Menu
    if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      columnMenu = false;
    }
    else {
      columnMenu = true;
    }

    // Type
    if(columnHeader.FieldType === 'String' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      type = 'string';
    }
    else if(columnHeader.FieldType === 'Double' || columnHeader.FieldType === 'Percentage' || columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      type = 'number';
    }
    else if(columnHeader.FieldType === 'Date') {
      type = 'date';
    }
    else {
      type = null;
    }

    // Field (Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component)
    if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'AxisTable' || columnHeader.FieldType === 'Resource' || columnHeader.FieldType === 'ResourceTable' || columnHeader.FieldType === 'Location' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend' || columnHeader.FieldName === 'Business_Line' || columnHeader.FieldName === 'Project' || columnHeader.FieldName === 'Workpackage' || columnHeader.FieldName === 'Action' || columnHeader.FieldName === 'Task' || columnHeader.FieldName === 'Entity') {
      field = columnHeader.FieldName;
      // field = columnHeader.FieldName + ".Label";
    }
    else if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      const date = new Date(columnHeader.FieldName);
      const columnName = date.getDate();

      field = "field_" + columnName;
    }
    else {
      field = columnHeader.FieldName;
    }

    // Format
    if(columnHeader.FieldType === 'Date') {
      if(formatDate === 'MM/DD/YYYY') {
        format = { type: 'date', format: 'MM/dd/yyyy' };
      }
      else if(formatDate === 'DD/MM/YYYY') {
        format = { type: 'date', format: 'dd/MM/yyyy' };
      }
      else if(formatDate === 'YYYY-MM-DD') {
        format = { type: 'date', format: 'yyyy-MM-dd' };
      }
    }
    else {
      format = null;
    }

    // Primary Key
    if(columnHeader.FieldName === 'Item_ID') {
      primaryKey = true;
    }
    else {
      primaryKey = false;
    }

    // Freeze Column
    // if(columns[i].FieldName === 'Name') {
    //   freeze = true;
    // }
    // else {
    //   freeze = false;
    // }

    // Edit
    if(columnHeader.FieldType === 'Date') {
      edit = { params: { firstDayOfWeek: 1, weekNumber: true } };
    }
    else {
      edit = null;
    }

    // Edit Type
    if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'Resource' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      editType = 'dropdownedit';
    }
    else if(columnHeader.FieldType === 'Date') {
      editType = 'datepickeredit';
    }
    else {
      editType = null;
    }

    // Reorder
    if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      reorder = false;
    }
    else {
      reorder = true;
    }

    // Resize
    if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      resize = false;
    }
    else {
      resize = true;
    }

    // Sort
    if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday' || columnHeader.FieldType === 'Weekend') {
      sort = false;
    }
    else {
      sort = true;
    }

    // Validation Rules
    if(columnHeader.FieldName === 'Name') {
      validationRules = this.nameRules;
    }
    else if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldName === 'Burned_Workload' || columnHeader.FieldName === 'Workload' || columnHeader.FieldName === 'Month_Burned') {
      validationRules = this.numberRules;
    }
    else {
      validationRules = null;
    }

    // Template
    // By FieldName
    if(columnHeader.FieldType === 'OpenDay') {
      template = this.templateColumnDay;
    }
    else if(columnHeader.FieldType === 'BankHoliday') {
      template = this.templateColumnBankHoliday;
    }
    else if(columnHeader.FieldType === 'Weekend') {
      template = this.templateColumnWeekend;
    }
    else if(columnHeader.FieldName === 'Action') {
      template = this.templateColumnAction;
    }
    else if(columnHeader.FieldName === 'Business_Line') {
      template = this.templateColumnBusinessLine;
    }
    else if(columnHeader.FieldName === 'Impact') {
      template = this.templateColumnImpact;
    }
    else if(columnHeader.FieldName === 'Item_Type') {
      template = this.templateColumnItemType;
    }
    else if(columnHeader.FieldName === 'Meteo') {
      template = this.templateColumnMeteo;
    }
    else if(columnHeader.FieldName === 'Month_Burned') {
      template = this.templateColumnMonthBurned;
    }
    else if(columnHeader.FieldName === 'Name') {
      template = this.templateColumnName;
    }
    else if(columnHeader.FieldName === 'Priority') {
      template = this.templateColumnPriority;
    }
    else if(columnHeader.FieldName === 'Probability') {
      template = this.templateColumnProbability;
    }
    else if(columnHeader.FieldName === 'Progress') {
      template = this.templateColumnProgress;
    }
    else if(columnHeader.FieldName === 'Project') {
      template = this.templateColumnProject;
    }
    else if(columnHeader.FieldName === 'Status') {
      template = this.templateColumnStatus;
    }
    else if(columnHeader.FieldName === 'Timeline') {
      template = this.templateColumnTimeline;
    }
    else if(columnHeader.FieldName === 'Trend') {
      template = this.templateColumnTrend;
    }
    else if(columnHeader.FieldName === 'Workpackage') {
      template = this.templateColumnWorkpackage;
    }
    // By Type
    else if(columnHeader.FieldType === 'Axe') {
      template = this.templateTypeAxe;
    }
    else if(columnHeader.FieldType === 'AxisTable') {
      template = this.templateTypeAxisTable;
    }
    else if(columnHeader.FieldType === 'Boolean') {
      template = this.templateTypeBoolean;
    }
    else if(columnHeader.FieldType === 'Date') {
      template = this.templateTypeDate;
    }
    else if(columnHeader.FieldType === 'Double') {
      template = this.templateTypeDouble;
    }
    else if(columnHeader.FieldType === 'HTML') {
      template = this.templateTypeHTML;
    }
    else if(columnHeader.FieldType === 'Link') {
      template = this.templateTypeLink;
    }
    else if(columnHeader.FieldType === 'Location') {
      template = this.templateTypeLocation;
    }
    else if(columnHeader.FieldType === 'Percentage') {
      template = this.templateTypePercentage;
    }
    else if(columnHeader.FieldType === 'Progress') {
      template = this.templateTypeProgress;
    }
    else if(columnHeader.FieldType === 'QRCode') {
      template = this.templateTypeQRCode;
    }
    else if(columnHeader.FieldType === 'Rating') {
      template = this.templateTypeRating;
    }
    else if(columnHeader.FieldType === 'Resource') {
      template = this.templateTypeResource;
    }
    else if(columnHeader.FieldType === 'ResourceTable') {
      template = this.templateTypeResourceTable;
    }
    else {
      template = this.templateTypeText;
    }

    // Width
    if(columnHeader.FieldType === 'OpenDay' || columnHeader.FieldType === 'BankHoliday') {
      width = 60;
      minWidth = 60;
      maxWidth = 60;
    }
    else if(columnHeader.FieldType === 'Weekend') {
      width = 56;
      minWidth = 56;
      maxWidth = 56;
    }
    else if(columnsWidth.find(column => column.Name === columnHeader.FieldName)) {
      width = columnsWidth.find(column => column.Name === columnHeader.FieldName).Width;
    }
    else {
      if(columnHeader.FieldName === 'Name') {
        minWidth = 300;
      }
      else if(columnHeader.FieldName === 'Status') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Priority') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Probability') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Impact') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Meteo') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Trend') {
        width = 115;
      }
      else if(columnHeader.FieldName === 'Progress') {
        width = 175;
      }
      else if(columnHeader.FieldName === 'Workload') {
        width = 135;
      }
      else if(columnHeader.FieldName === 'Burned_Workload') {
        width = 145;
      }
      else if(columnHeader.FieldName === 'StartDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'EndDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'Timeline') {
        width = 200;
      }
      else if(columnHeader.FieldName === 'Resource') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'Task_Type') {
        width = 170;
      }
    }

    // Define Column object for Grid
    var colObj = {
      allowEditing: this.getColumnEditable(columnHeader.EditionItemTypes),
      allowReordering: reorder,
      allowResizing: resize,
      allowSorting: sort,
      edit: edit,
      editType: editType,
      field: field,
      format: format,
      headerTemplate: headerTemplate,
      headerText: columnHeader.Label,
      headerTextAlign: headerTextAlign,
      isPrimaryKey: primaryKey,
      lockColumn: false,
      template: template,
      type: type,
      validationRules: validationRules,
      visible: true,
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth
    };

    return colObj;
  }

  filterColumns(columnName) {
    // Call the event from the Parent component through the props
    this.props.onColumnsFilter(columnName);
  }

  sortColumn(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " DESC")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Descending")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName)) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName + " DESC";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Ascending")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName + " DESC";
      }
      else {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
    }
    else {
      currentView.Parameters.push({ Name: 'Sort', Value: columnName, ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Call the event from the Parent component through the props
    this.props.onColumnSort(currentView);
  }

  sortColumns(columnName) {
    // Call the event from the Parent component through the props
    this.props.onColumnsSort(columnName);
  }

  autofitColumn(columnName) {
    const currentView = this.state.currentView;
    let columns = [], columnsWidth = [];

    columns.push(columnName);

    this.grid.autoFitColumns(columns);

    // Loop through the Grid columns to build CurrentView Columns Width
    for(let i=0; i < this.grid.columns.length; i++) {
      // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
      const columnName = this.grid.columns[i].field.replace(".Label", "");

      columnsWidth.push({ Name: columnName, Width: this.grid.columns[i].width });
    }

    // Update Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    }
    // Create Current View Columns Width
    else {
      currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  autofitAllColumns() {
    const currentView = this.state.currentView;
    let columnsWidth = [];

    this.grid.autoFitColumns();

    // Loop through the Grid columns to build CurrentView Columns Width
    for(let i=0; i < this.grid.columns.length; i++) {
      // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
      const columnName = this.grid.columns[i].field.replace(".Label", "");

      columnsWidth.push({ Name: columnName, Width: this.grid.columns[i].width });
    }

    // Update Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    }
    // Create Current View Columns Width
    else {
      currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  resizeStop(args) {
    const currentView = this.state.currentView;
    let columnsWidth = [];

    // Loop through the Grid columns to build CurrentView Columns Width
    for(let i=0; i < this.grid.columns.length; i++) {
      // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
      const columnName = this.grid.columns[i].field.replace(".Label", "");

      columnsWidth.push({ Name: columnName, Width: this.grid.columns[i].width });
    }

    // Update Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    }
    // Create Current View Columns Width
    else {
      currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  onHolidayClick(args) {
    const { month, year } = this.state;

    // Click on Table element e-rowcell
    if(args.target.closest('.e-rowcell')) {
      const classList = args.target.classList;
      const day = args.target.id;
      const element = month + "/" + day + "/" + year;

      // Get element informations to build request
      const split = element.split(' ');
      const itemType = split[1];
      const itemId = split[0].substring(1);
      const columnName = split[2];
      const allowEditing = split[3];

      let oldValue, newValue;
      
      // Check if Click on Holiday element
      if(classList.contains('iconsTimeTrackingHoliday')) {
        // Get Holiday new value
        if(classList.contains('iconFullCircle')) {
          newValue = 0.5;
        }
        else if(classList.contains('iconHalfCircle')) {
          newValue = 0;
        }
        else if(classList.contains('iconCircle')) {
          newValue = 1;
        }

        // Save Holiday
        this.saveHolidays(element, newValue);
      }
      // Check if Editing is allowed
      if(allowEditing === "true") {
        // Check if Click on Boolean element
        if(classList.contains('boolean')) {
          // Get Boolean new value
          if(classList.contains('iconBooleanTrue')) {
            oldValue = "True";
            newValue = "False";
          }
          else if(classList.contains('iconBooleanFalse')) {
            oldValue = "False";
            newValue = "True";
          }
        }
        else if(classList.contains('rating')) {
          // Get Boolean old and new value
          oldValue = split[4];
          newValue = split[5];
        }

        if(newValue != oldValue) {
          // Call the event from the Parent component through the props
          this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
        }
      }

      this.tableClicked = false;
    }
  }

  onHolidayDoubleClick(args) {
    // Double Click on Table element
    if(args.target) {
      const classList = args.target.classList;

      try{
        // Check if Double Click on Holiday element
        if(classList.contains('e-rowcell') || classList.contains('iconsTimeTrackingHoliday')) {
          this.grid.closeEdit();
          this.grid.endEdit();
        }
        // Check if Double Click on Weekend Holiday element
        else if(classList.contains('e-rowcell') || classList.contains('iconsTimeTrackingWeekendHoliday')) {
          this.grid.closeEdit();
          this.grid.endEdit();
        }

        this.tableClicked = false;
      }
      catch(e) {
        
      }
    }
  }

  async saveHolidays(date, weight) {
    const { login, authId, language, itemId } = this.state;

    // Get Day, Month and Year of the Holiday
    const holiday = new Date(date);
    const day = holiday.getDate();
    const month = holiday.getMonth() + 1;
    const year = holiday.getFullYear();

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify([{
        'Year': year,
        'Month': month,
        'Day': day,
        'ResourceId': parseInt(itemId),
        'Weight': parseFloat(weight)
      }])
    };
    
    try{
      const response = await fetch(API_save_holidays, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SaveHolidayModificationsResult;

      // Check request response
      if(result) {
        // Update Table with Holiday Modification
        this.updateHolidays(date, weight);

        // Update Rows in Components DataSource
        this.updateHolidaysDatasource(itemId, date, weight);
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async updateHolidays(date, value) {
    let rows = this.state.rows;

    rows.forEach(row => {
      // Loop only throught the Holidays
      if(row.ItemType === 'Holiday') {
        // Update Row with Modification NewValue
        if(row.Cells.find(cell => cell.ColumnName === date)) {
          row.Cells.find(cell => cell.ColumnName === date).Value = value;
        }
      }
    });

    // Call the event from the Parent component through the props
    this.props.onTableChange(rows);
  }

  updateHolidaysDatasource(resourceId, date, value) {
    const day = new Date(date).getDate();
    const columnName = 'field_' + day;
    let currentRow = this.grid.dataSource[0];

    // Update Cell in Current Row
    currentRow[columnName] = value;

    // Update Tree Row
    this.grid.updateRow(0, currentRow);

    // if(this.grid && this.grid.columns.find(column => column.field === columnName)) {
    //   this.grid.setCellValue('Holiday', columnName, value);
    // }
  }

  updateDatasource(modification, propagations, warnings) {
    let dictModifications = {};

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

    // Get Column Type
    const columnType = this.getColumnType(modification.ColumnName);

    // Apply Modification on Row
    if((columnType !== 'Date' || modification.ColumnName === 'StartDate' || modification.ColumnName === 'EndDate') && (columnType !== 'Double' || modification.ColumnName === 'DayCost' || modification.ColumnName === 'Meteo' || modification.ColumnName === 'Trend') && (columnType !== 'String' || modification.ColumnName === 'BackColor' || modification.ColumnName === 'ForeColor')) {
      this.updateDictModifications(dataSource, dictModifications, modification.ItemType, modification.ItemId, modification.ColumnName, modification.NewValue);
    }

    // Apply Propagations in Tree DataSource
    propagations.forEach(propagation => {
      this.updateDictModifications(dataSource, dictModifications, propagation.ToItemType, propagation.ToItemId, propagation.ColumnName, propagation.NewValue);
    });

    // Update Table Row
    // Object.keys(dictModifications).forEach(key => {
    //   this.grid.updateRow(key, dictModifications[key]);
    // });

    // Update Datasource
    if(Object.keys(dictModifications).length > 0) {
      this.grid.dataSource = dataSource;
    }

    // // Apply Modification on Row
    // this.updateGridCell(modification.ItemType, modification.ItemId, modification.ColumnName, modification.NewValue);

    // // Apply Propagations in Tree DataSource
    // propagations.forEach(propagation => {
    //   this.updateGridCell(propagation.ToItemType, propagation.ToItemId, propagation.ColumnName, propagation.NewValue);
    // });

    // if(warnings.length > 0) {
    //   warningMessage = warnings.join(' | ');
    // }

    // // Apply Warnings on Row
    // this.updateGridCell(modification.ItemType, modification.ItemId, 'WarningMessage', warningMessage);
  }

  updateDictModifications(dataSource, dictModifications, itemType, itemId, columnName, newValue) {
    const dictColumns = this.state.dictColumns;
    const modificationId = this.getRowItemId(itemType, itemId);
    const index = this.getRowIndex(modificationId);
    let currentRow;

    if(index >= 0 && Object.keys(dictColumns).find(column => column === columnName)) {
      // Format Modification Value
      const value = this.getModificationValue(columnName, newValue);

      if(!Object.keys(dictModifications).find(modification => modification === index)) {
        // Get Current Row
        currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
        // currentRow = this.grid.dataSource.find(row => row['Item_ID'] === modificationId);

        dictModifications[index] = currentRow;
      }

      // Update Modifications Dictionary 
      dictModifications[index][columnName] = value;
    }
  }

  updateGridCell(itemType, itemId, columnName, newValue) {
    const modificationId = this.getRowItemId(itemType, itemId);
    let currentRow, index, value;

    if(this.grid && this.grid.columns.find(column => column.field.replace(".Label", "") === columnName)) {
      // Get Column Type
      const columnType = this.getColumnType(columnName);
      
      // Parse Object for Axes
      if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Resource' || columnType === 'ResourceTable' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend') {
        value = JSON.parse(newValue);
      }
      // Parse Double
      else if(columnType === 'Double') {
        value = parseFloat(newValue);
      }
      // Parse Date
      else if(columnType === 'Date') {
        if(newValue) {
          value = new Date(newValue);
        }
        else {
          value = "";
        }
      }
      else {
        value = newValue;
      }

      // Specific case for Text Editor
      // if(columnType === 'HTML') {
      //   this.setState({ rowItemValue: newValue });
      // }

      // Find current Row to apply Modification
      if(this.grid.dataSource.find(row => row['Item_ID'] === modificationId)) {
        // Get Row index
        index = this.grid.dataSource.findIndex(row => row['Item_ID'] === modificationId);

        // Get Current Row
        currentRow = this.grid.dataSource.find(row => row['Item_ID'] === modificationId);

        // Update Cell in Current Row
        currentRow[columnName] = value;

        // Update Tree Row
        this.grid.updateRow(index, currentRow);
      }

      // this.grid.setCellValue(modificationId, columnName, value);
    }
  }

  // updateDatasource(modification, propagations, warnings) {
  //   const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
  //   const columnName = modification.ColumnName;
  //   const newValue = modification.NewValue;
  //   let currentRow, index;
  //   let applyModification = false;

  //   // Clone Datasource
  //   let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

  //   // Find current Row to apply Modification
  //   if(dataSource.find(row => row['Item_ID'] === modificationId)) {
  //     currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
  //   }

  //   // Find Index of current Row in visible Records
  //   // if(this.grid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
  //   //   index = this.grid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
  //   // }

  //   // Get Column Type
  //   const columnType = this.getColumnType(columnName);

  //   // Build Updated Rows
  //   // let updatedRows = [];

  //   if(currentRow && (columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Boolean' || columnType === 'Date' || columnType === 'HTML' || columnType === 'Location' || columnType === 'Rating' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'StartDate' || columnName === 'EndDate')) {
  //     // Parse Object for Axes
  //     if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Location' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend') {
  //       // Update Current Row Column
  //       currentRow[columnName] = JSON.parse(newValue);
  //     }
  //     // Parse Date
  //     else if(columnType === 'Date') {
  //       // Update Current Row Column
  //       if(newValue) {
  //         currentRow[columnName] = new Date(newValue);
  //       }
  //       else {
  //         currentRow[columnName] = "";
  //       }
  //     }
  //     else {
  //       // Update Current Row Column
  //       currentRow[columnName] = newValue;
  //     }

  //     // Add updated Row
  //     // updatedRows.push({ "Index": index, "Row": currentRow });

  //     applyModification = true;
  //   }

  //   // Apply Propagations in Table DataSource
  //   propagations.forEach(propagation => {
  //     const propagationId = propagation.ToItemType.substring(0,1) + propagation.ToItemId;
  //     const propagationName = propagation.ColumnName;
  //     const propagationValue = propagation.NewValue;
  //     let propagationRow, propagationIndex;

  //     // Get Propagation Type
  //     const propagationType = this.getColumnType(propagationName);

  //     // Check if we find Column to apply Propagation
  //     if(this.grid.columns.find(column => column.field.replace(".Label", "") === propagationName)) {
  //       // Find propagation Row to apply Modification
  //       if(dataSource.find(row => row['Item_ID'] === propagationId)) {
  //         propagationRow = dataSource.find(row => row['Item_ID'] === propagationId);
  //       }

  //       // Find Index of propagation Row in visible Records
  //       // if(this.grid.getVisibleRecords().find(row => row['Item_ID'] === propagationId)) {
  //       //   propagationIndex = this.grid.getVisibleRecords().findIndex(row => row['Item_ID'] === propagationId);
  //       // }

  //       if(propagationRow) {
  //         // Parse Object for Axes
  //         if(propagationType === 'Axe' || propagationType === 'AxisTable' || propagationType === 'Resource' || propagationType === 'ResourceTable' || propagationName === 'Meteo' || propagationName === 'Trend') {
  //           // Update Propagation Row Column
  //           propagationRow[propagationName] = JSON.parse(propagationValue);
  //         }
  //         // Parse Date
  //         else if(propagationType === 'Date') {
  //           // Update Propagation Row Column
  //           if(propagationValue) {
  //             propagationRow[propagationName] = new Date(propagationValue);
  //           }
  //           else {
  //             propagationRow[propagationName] = "";
  //           }
  //         }
  //         else {
  //           // Update Propagation Row Column
  //           propagationRow[propagationName] = propagationValue;
  //         }

  //         // Add updated Row
  //         // if(!updatedRows.find(row => row.Index === propagationIndex)) {
  //         //   updatedRows.push({ "Index": propagationIndex, "Row": propagationRow });
  //         // }

  //         applyModification = true;
  //       }
  //     }
  //   });

  //   // Apply Warnings in Table DataSource
  //   if(currentRow) {
  //     let warningMessage = '';

  //     if(warnings.length > 0) {
  //       warningMessage = warnings.join(' | ');
  //     }

  //     if(currentRow['WarningMessage'] && currentRow['WarningMessage'] !== warningMessage) {
  //       // Update Current Row Warning
  //       currentRow['WarningMessage'] = warningMessage;

  //       // Add updated Row
  //       // if(!updatedRows.find(row => row.Index === index)) {
  //       //   updatedRows.push({ "Index": index, "Row": currentRow });
  //       // }

  //       applyModification = true;
  //     }
  //   }

  //   // Apply Update on Current Row
  //   // if(this.grid) {
  //   //   this.grid.updateRow(index, currentRow);
  //   // }

  //   // Update Datasource
  //   if(applyModification) {
  //     this.grid.dataSource = dataSource;
  //   }
  // }

  cancelModification(modification) {
    let dictModifications = {};
    let day, month, year;

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

    if(modification.AdditionalContext.find(value => value.Key === 'Day')) {
      day = modification.AdditionalContext.find(value => value.Key === 'Day').Value;
    }
    if(modification.AdditionalContext.find(value => value.Key === 'Month')) {
      month = modification.AdditionalContext.find(value => value.Key === 'Month').Value;
    }
    if(modification.AdditionalContext.find(value => value.Key === 'Year')) {
      year = modification.AdditionalContext.find(value => value.Key === 'Year').Value;
    }

    // Format Date to MM/DD/YYYY
    // columnName = new Date(year, month-1, day).toLocaleString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).split(' ')[0];

    // Apply Modification on Row
    this.updateDictModifications(dataSource, dictModifications, modification.ItemType, modification.ItemId, "field_" + day, modification.OldValue);

    // Update Table Row
    // Object.keys(dictModifications).forEach(key => {
    //   this.grid.updateRow(key, dictModifications[key]);
    // });

    // Update Datasource
    if(Object.keys(dictModifications).length > 0) {
      this.grid.dataSource = dataSource;
    }

    // Apply Modification on Row
    // this.updateGridCell(modification.ItemType, modification.ItemId, "field_" + columnName, modification.OldValue);
  }

  // cancelModification(modification) {
  //   const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
  //   // const columnName = modification.ColumnName;
  //   const oldValue = modification.OldValue;
  //   let columnName, day, month, year;
  //   let currentRow, index;
  //   let applyModification = false;

  //   if(modification.AdditionalContext.find(value => value.Key === 'Day')) {
  //     day = modification.AdditionalContext.find(value => value.Key === 'Day').Value;
  //   }
  //   if(modification.AdditionalContext.find(value => value.Key === 'Month')) {
  //     month = modification.AdditionalContext.find(value => value.Key === 'Month').Value;
  //   }
  //   if(modification.AdditionalContext.find(value => value.Key === 'Year')) {
  //     year = modification.AdditionalContext.find(value => value.Key === 'Year').Value;
  //   }

  //   // Format Date to MM/DD/YYYY
  //   columnName = new Date(year, month-1, day).toLocaleString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).split(' ')[0];

  //   // Clone Datasource
  //   let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

  //   // Find current Row to apply Modification
  //   if(dataSource.find(row => row['Item_ID'] === modificationId)) {
  //     currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
  //   }

  //   // Find Index of current Row in visible Records
  //   // if(this.grid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
  //   //   index = this.grid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
  //   // }

  //   // Get Column Type
  //   const columnType = this.getColumnType(columnName);

  //   if(currentRow && (columnType !== 'Axe' && columnType !== 'AxisTable' && columnType !== 'HTML' && columnType !== 'Resource' && columnType !== 'ResourceTable' && columnName !== 'Meteo' && columnName !== 'Trend')) {
  //     // Parse Date
  //     if(columnType === 'Date') {
  //       // Update Current Row Column with old Value
  //       if(oldValue) {
  //         currentRow[columnName] = new Date(oldValue);
  //       }
  //       else {
  //         currentRow[columnName] = "";
  //       }
  //     }
  //     else {
  //       // Update Current Row Column with old Value
  //       currentRow[columnName] = oldValue;
  //     }

  //     // if(this.grid) {
  //     //   this.grid.updateRow(index, currentRow);
  //     // }

  //     applyModification = true;
  //   }

  //   // Update Datasource
  //   if(applyModification) {
  //     this.grid.dataSource = dataSource;
  //   }
  // }

  // Format Cells value for Export
  excelQueryCellInfo(args) {
    const columnName = args.column.field.replace(".Label", "");
    const columnType = this.getColumnType(columnName);
    const value = args.data[columnName];

    // Dates
    if(value && columnType === 'Date') {
      args.value = new Date(value).toLocaleDateString();
    }
    // Percentage
    else if(columnType === 'Percentage') {
      if(args.value) {
        args.value = value.toFixed(0) + "%";
      }
      else {
        args.value = "0%";
      }
    }
    // QRCode
    else if(args.column.headerText === 'QR Code') {
      args.value = "";
    }
    // Objects
    else if(value && (columnType === 'Axe' || columnType === 'Resource' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Timeline' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity')) {
      args.value = value.Label;
    }
    // Table Objects
    else if(value && (columnType === 'AxisTable' || columnType === 'ResourceTable' || columnType === 'Files' || columnName === 'Rights')) {
      let string = "";

      value.forEach((item, index) => {
        if(index < value.length - 1) {
          string = string.concat(item.Label, ", ");
        }
        else {
          string = string.concat(item.Label);
        }
      });

      args.value = string;
    }
  }

  exportCSV() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".csv";

    if(this.grid) {
      // Define Export File Name
      let csvExportProperties = { fileName: fileName };

      this.grid.csvExport(csvExportProperties);
    }
  }

  exportXLSX() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".xlsx";

    if(this.grid) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };
      
      this.grid.excelExport(excelExportProperties);
    }
  }

  searchItem(search) {
    if(this.grid) {
      this.grid.search(search);
    }
  }

  validateMessage() {
    this.setState({ info: false });
  }

  updateErrors(error) {
    // Display Popup with Error message
    this.setState({ info: true, informationMessage: error });
  }

  // ----- ----- Validation Functions ----- -----
  nameValidation(args) {
    return getValue('value', args).length <= 100;
  }

  numberValidation(args) {
    return getValue('value', args) >= 0;
  }

  // ----- ----- Format Functions ----- -----
  templateHeader(props) {
    const { itemId, itemType, blockType } = this.state;

    // Get Column Name and Value (Remove Suffix .Label to Object Fields)
    const columnName = props.field.replace(".Label", "");
    const label = props.headerText;
    let columnType, editable = false, filter = false, sort = false;

    // Column Indicators
    if(columnName !== 'Index') {
      columnType = this.getColumnType(columnName);
      editable = this.getColumnEditable(this.getColumnEditionItemType(columnName));
      filter = this.getColumnFilter(columnName);
      sort = this.getColumnSort(columnName);
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverColumnActions ItemId={itemId} ItemType={itemType} BlockType={blockType} ColumnName={columnName} ColumnType={columnType} Editable={editable} onColumnsFilter={this.filterColumns} onColumnsSort={this.sortColumns} onColumnAutofit={this.autofitColumn} onColumnsAutofit={this.autofitAllColumns}></PopoverColumnActions>
        </Popover.Content>
      </Popover>
    );

    return <div className={"flex space-between align-items-center" + (editable ? " columnHeaderEditable" : " columnHeaderNotEditable")}>
      <span className="flex-start" onClick={() => this.sortColumn(columnName)}>{label}</span>
      <div className="flex-end">
        {/* Column Indicators */}
        {filter && <span className={(editable ? "iconColumnFilter" : "iconColumnFilterGrey") + " iconsTemplateHeader mh5"}></span>}
        {sort === 'ascending' && <span className={(editable ? "iconArrowDown" : "iconArrowDownGrey") + " iconsTemplateHeader mh5"}></span>}
        {sort === 'descending' && <span className={(editable ? "iconArrowUp" : "iconArrowUpGrey") + " iconsTemplateHeader mh5"}></span>}
        {/* Column Actions Popover */}
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <span className={(editable ? "iconColumnActions" : "iconColumnActionsGrey") + " iconsTemplateHeader mh5"}></span>
        </OverlayTrigger>
      </div>
    </div>;
  }

  templateColumnHeaderDay(props) {
    const label = props.headerText;
    const field = props.field;
    const date = new Date(field);
    const day = date.getDay();

    const today = new Date();

    // Weekends
    if(day === 0 || day === 6) {
      // Today
      if(date.toDateString() === today.toDateString()) {
        return <div className="headerToday">
          <span className="white">{label}</span>
        </div>;
      }
      else {
        return <div className="smoke-grey">{label}</div>;
      }
    }
    else {
      // Today
      if(date.toDateString() === today.toDateString()) {
        return <div className="headerToday">
          <span className="white">{label}</span>
        </div>;
      }
      else {
        return <div className="">{label}</div>
      }
    }
  }
  
  // ----- ----- Columns
  templateColumnDay(props) {
    const itemType = props.Item_Type;

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    // Format Holidays
    if(itemType === 'Holiday') {
      if(value == 1) {
        return <div className="holidaysTable">
          <div id={field.replace("field_", "")} className="iconsTimeTrackingHoliday iconFullCircle bg-turquoise"></div>
        </div>;
      }
      else if(value == 0.5) {
        return <div className="holidaysTable">
          <div id={field.replace("field_", "")} className="iconsTimeTrackingHoliday iconHalfCircle bg-turquoise"></div>
        </div>;
      }
      else {
        return <div className="holidaysTable">
          <div id={field.replace("field_", "")} className="iconsTimeTrackingHoliday iconCircle bg-grey"></div>
        </div>;
      }
    }
    // Format Tasks
    else if(itemType === 'Task') {
      if(value > 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value == 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value > 0 && value < 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value == 0) {
        return <div className="timetrackingDay grey">{value}</div>;
      }
      else {
        return <div className=""></div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnBankHoliday(props) {
    const itemType = props.Item_Type;

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    // Format Holidays
    if(itemType === 'Holiday') {
      return <div className="holidaysTable">
        <div className="iconsTimeTrackingBankHoliday iconFullCircle bg-dark-grey"></div>
      </div>;
    }
    // Format Tasks
    else if(itemType === 'Task') {
      if(value > 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value == 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value > 0 && value < 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value == 0) {
        return <div className="timetrackingDay grey">-</div>;
      }
      else {
        return <div className=""></div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnWeekend(props) {
    const itemType = props.Item_Type;

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    // Format Holidays
    if(itemType === 'Holiday') {
      return <div className="holidaysTable">
        <div id={field} className="iconsTimeTrackingWeekendHoliday iconFullCircle bg-smoke-grey"></div>
      </div>;
    }
    // Format Tasks
    else if(itemType === 'Task') {
      if(value > 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value == 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value > 0 && value < 1) {
        return <div className="timetrackingDay bold">{value}</div>;
      }
      else if(value == 0) {
        return <div className="timetrackingDay grey">-</div>;
      }
      else {
        return <div className=""></div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnAction(props) {
    const action = props.Action;

    if(action) {
      if(action.Id === 1) {
        return <div className="axe brd-dashed very-light-grey">{action.Label}</div>;
      }
      else if(action.Label) {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/Action/${action.Id}/Details`}><span className="iconAction verysmallIcons mr5"></span></a>
          <span className="">{action.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnBusinessLine(props) {
    const businessLine = props.Business_Line;

    if(businessLine) {
      if(businessLine.Id === 1) {
        return <div className="axe brd-dashed very-light-grey">{businessLine.Label}</div>;
      }
      else if(businessLine.Label) {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/Business_Line/${businessLine.Id}/Details`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
          <span className="">{businessLine.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnImpact(props) {
    const impact = props.Impact;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact) {
      switch(impact.Id) {
        case 0:
            return <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minicardPriority black">{impact.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minicardPriority black">{impact.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minicardPriority red">{impact.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnItemType(props) {
    const itemType = props.Item_Type;

    switch(itemType) {
      case 'Business_Line':
          return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{itemType}</div>;
      case 'Project':
          return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{itemType}</div>;
      case 'Workpackage':
          return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{itemType}</div>;
      case 'Action':
          return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{itemType}</div>;
      case 'Task':
          return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{itemType}</div>;
      default:
          return <div className="">{itemType}</div>;
    }
  }

  templateColumnMeteo(props) {
    const meteo = props.Meteo;

    // Meteo (0: None, 1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
    if(meteo) {
      switch(meteo.Id) {
        case 1:
            return <img className="iconsTable" src={IconSun} alt="Sun"/>;
        case 2:
            return <img className="iconsTable" src={IconCloud} alt="Cloud"/>;
        case 3:
            return <img className="iconsTable" src={IconRain} alt="Rain"/>;
        case 4:
            return <img className="iconsTable" src={IconThunder} alt="Thunder"/>;
        case 5:
            return <img className="iconsTable" src={IconMoon} alt="Moon"/>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnMonthBurned(props) {
    const monthBurned = props.Month_Burned;

    if(monthBurned == 0) {
      return <div className="monthBurned brd-dashed-timetracking blue bold">{monthBurned}</div>;
    }
    else {
      return <div className="monthBurned brd-timetracking blue bold">{monthBurned}</div>;
    }
  }

  templateColumnName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;
    const businessLine = props.Business_Line;
    const project = props.Project;
    const workpackage = props.Workpackage;
    const action = props.Action;
    const workload = props.Workload;
    const burned = props.Burned_Workload;
    let remaining;

    // Calcul Remaining on Task
    if(workload >= 0 && burned >= 0) {
      if(workload === burned) {
        remaining = 0;
      }
      else {
        remaining = parseFloat((workload - burned).toPrecision(3));
      }
    }
    else if(workload >= 0) {
      remaining = parseFloat((workload).toPrecision(3));
    }
    else {
      remaining = 0;
    }

    if(itemType === 'Holiday') {
      return <div className="turquoise bold"><span className="iconHolidays tableIcon verysmallIcons"></span>{name}</div>;
    }
    else if(itemType === 'Task' && project && workpackage && action) {
      return <div className="line-height20">
        <div className="row"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Details`}><span title={itemType} className="iconTaskGrey treeIconGrey verysmallIcons" alt="Task"></span></a>{name}<span className={"bold ml5" + (remaining == 0 ? " grey" : " blue")}>{remaining}</span></div>
        <div className="row"><span className="fs11 grey">{project.Label} / {workpackage.Label} / {action.Label}</span></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnPriority(props) {
    const priority = props.Priority;

    // Priority (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(priority) {
      switch(priority.Id) {
        case 0:
            return <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minicardPriority black">{priority.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minicardPriority black">{priority.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minicardPriority red">{priority.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProbability(props) {
    const probability = props.Probability;

    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(probability) {
      switch(probability.Id) {
        case 0:
            return <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minicardPriority black">{probability.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minicardPriority black">{probability.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minicardPriority red">{probability.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProgress(props) {
    const itemType = props.Item_Type;
    let progress = props.Progress;
    // progress = (props.Progress.replace(',', '.') * 100).toFixed(0);
    
    if(progress) {
      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      if(itemType === 'Holiday') {
        return null;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`0 %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
        </div>;
      }
    }
  }

  templateColumnProject(props) {
    const project = props.Project;

    if(project) {
      if(project.Id === 1) {
        return <div className="axe brd-dashed very-light-grey">{project.Label}</div>;
      }
      else if(project.Label) {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/Project/${project.Id}/Details`}><span className="iconProject verysmallIcons mr5"></span></a>
          <span className="">{project.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnStatus(props) {
    const status = props.Status;

    // Status (1: To be Done, 2: In Progress, 3: On Hold, 4: Cancelled, 5: Completed, 6: Open, 7: Closed)
    // Status (8: Realised, 9: To be Scheduled, 10: Scheduled, 11: Taken, 12: To be Taken)
    if(status) {
      switch(status.Id) {
        case 1:
        case 9:
            return <div className="axe brd-radius bg-grey white">{status.Label}</div>;
        case 2:
        case 10:
            return <div className="axe brd-radius bg-blue white">{status.Label}</div>;
        case 3:
            return <div className="axe bordered orange">{status.Label}</div>;
        case 4:
            return <div className="axe bordered grey">{status.Label}</div>;
        case 5:
        case 7:
        case 11:
            return <div className="axe brd-radius bg-green white">{status.Label}</div>;
        case 6:
        case 12:
            return <div className="axe brd-radius bg-orange white">{status.Label}</div>;
        case 8:
            return <div className="axe brd-radius bg-red white">{status.Label}</div>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTimeline(props) {
    let status = props.Status;
    let startDate = props.StartDate;
    let endDate = props.EndDate;

    // Create Timeline with different colors based on status, StartDate and EndDate
    if(status && startDate && endDate) {
      // Format Date to English format (it is Date object after modification in Table Datepicker)
      if(startDate instanceof Date) {
        startDate = startDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      if(endDate instanceof Date) {
        endDate = endDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }

      return <Timeline View={'Block'} Status={status} StartDate={startDate} EndDate={endDate}></Timeline>;
    }
    else {
      return <div className=""></div>;
    }
  }

  templateColumnTrend(props) {
    const trend = props.Trend;

    // Trend (0: None, 1: Down ⬊, 2: Right ⮕, 3: Up ⬈)
    if(trend) {
      switch(trend.Id) {
        case 1:
            return <div className="iconTrendDown iconsTable"></div>;
        case 2:
            return <div className="iconTrendRight iconsTable"></div>;
        case 3:
            return <div className="iconTrendUp iconsTable"></div>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnWarning(props) {
    const warningMessage = props.WarningMessage;

    if(warningMessage) {
      return <OverlayTrigger key="right" placement="right" overlay={<Tooltip id="tooltip-right" className="tooltip-warning">{warningMessage}</Tooltip>}>
        <Badge className="badgeTable bg-white" pill><i className="iconWarningRed verysmallIcons"/></Badge>
      </OverlayTrigger>;
    }
    else {
      return null;
    }
  }

  templateColumnWorkpackage(props) {
    const workpackage = props.Workpackage;

    if(workpackage) {
      if(workpackage.Id === 1) {
        return <div className="axe brd-dashed very-light-grey">{workpackage.Label}</div>;
      }
      else if(workpackage.Label) {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/Workpackage/${workpackage.Id}/Details`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
          <span className="">{workpackage.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  // ----- ----- Type
  templateTypeAxe(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    let backColor, foreColor, borderColor;

    // Back, Fore & Border Color
    if(value) {
      if(!value.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = value.BackColor;
      }
      if(!value.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
          borderColor = value.BackColor;
        }
        else {
          borderColor = value.ForeColor;
        }

        foreColor = value.ForeColor;
      }
    }

    if(value) {
      if(value.Label === '- NA -') {
        return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeAxisTable(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];

    if(values) {
      return <div className="displayblock">
        {values.map((value, index) => {
          let backColor, foreColor, borderColor;

          // Back, Fore & Border Color
          if(value) {
            if(!value.BackColor) {
              backColor = '#FFFFFF';
            }
            else {
              backColor = value.BackColor;
            }
            if(!value.ForeColor) {
              foreColor = '#0088C7';
              borderColor = '#0088C7';
            }
            else {
              if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                borderColor = value.BackColor;
              }
              else {
                borderColor = value.ForeColor;
              }

              foreColor = value.ForeColor;
            }
          }

          return <div key={index} className={"axisTable bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeBoolean(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == "True") {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeDate(props) {
    const formatDate = this.state.formatDate;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let date;

    if(value) {
      if(formatDate === 'MM/DD/YYYY') {
        date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      else if(formatDate === 'DD/MM/YYYY') {
        date = new Date(value).toLocaleDateString("fr-FR");
      }
      else if(formatDate === 'YYYY-MM-DD') {
        date = this.formatDateKr(new Date(value));
      }
    }

    return <div className="">{date}</div>;
  }

  templateTypeDouble(props) {
    const dictColumns = this.state.dictColumns;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let unit;

    if(column) {
      unit = column.Unit;
    }

    if(value || value === 0) {
      if(unit) {
        return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
      }
      else {
        return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeHTML(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    if(value) {
      return <div dangerouslySetInnerHTML={{ __html: value }}/>;
    }
    else {
      return null;
    }
  }

  templateTypeLink(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    const split = value.split('/');
    const shortName = split[split.length-1];

    if(value) {
      return <div title={value} className=""><a target="_self" className="" href={value}><span className="treeIcon iconLink iconsPath" alt="Link"></span>{shortName}</a></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLocation(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    if(value) {
      if(value.Label === '- NA -') {
        return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="location">
          <span className="iconMap verysmallIcons"></span>
          <span className="">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypePercentage(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let percentage = value;
    // percentage = (value * 100).toFixed(0);

    if(value) {
      return <div className="progressBlock">
        <span className="progressLabel">{`${percentage} %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={percentage}></ProgressBar></span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeProgress(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let progress;
    
    if(value) {
      progress = (value.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateTypeQRCode(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    const url = window.location.origin + '/Card/' + itemType + '/' + itemId.substring(1) + '/Details';

    if(itemId && itemType && url) {
      return <QRCode value={url} size={64} style={{ height: 'auto', width: 'auto' }} viewBox={`0 0 64 64`}/>;
    }

    // Get ColumnName and Value
    // const columnName = this.getColumnName(props);
    // const value = props[columnName];

    // if(value) {
    //   return <img style={{ height: '50px', width: '50px' }} src={'data:image/jpeg;base64,' + value}/>;
    // }
  }

  templateTypeRating(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing + " " + value;

    if(value == 5) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRating iconsRating mr10"></div>
      </div>;
    }
    if(value == 4) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 3) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 2) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 1) {
      return <div className="ratingTable">
        <div id={id + " 0"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value === 0) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
  }

  templateTypeResource(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    if(value) {
      if(value.Id === 1) {
        const firstname = 'N';
        const lastname = 'A';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Label === '- To be assigned -') {
        const firstname = 'T';
        const lastname = 'B';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Id && value.Label) {
        let firstname, lastname;
        const split = value.Label.split(' ');

        if(split.length === 1) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[1].substring(0,1);
        }
        else {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[split.length-1].substring(0,1);
        }

        return <div className="align-baseline">
          <a target="_self" href={`/Card/Resource/${value.Id}/Details`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeResourceTable(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            let firstname, lastname;
            const split = value.Label.split(' ');
      
            if(split.length === 1) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[split.length-1].substring(0,1);
            }

            if(value.Id === 0) {
              return <div key={index} className="multiResource mr10">{value.Label}</div>;
            }
            else {
              return <div key={index} className="multiResource mr10">
                <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                <span className="ml5 grey">{value.Label}</span>
              </div>;
            }
          })}
        </div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeText(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    return <div className="">{value}</div>;
  }

  // ----- ----- Aggregate Functions ----- -----
  customAggregate(rows, column) {
    const sampleData = getObject('result', rows);
    const columnName = column.columnName;
    let sum = 0;

    if(sampleData) {
      sampleData.filter((item) => {
        let data = getObject(columnName, item);
  
        if(data) {
          sum += parseFloat(data);
        }
      });
    }

    return sum;
  }

  footerTemplateOpenDay(props) {
    if(props) {
      const sum = getObject('Custom', props);
      // const sum = getObject('Count', props);

      if(sum === 0) {
        return <div className="timetrackingDayTotal brd-dashed-grey-timetrackingDay">
          <span className="light-grey center">{sum}</span>
        </div>;
      }
      else if(sum > 0 && sum < 1) {
        return <div className="timetrackingDayTotal brd-timetrackingDay">
          <span className="grey center">{parseFloat(sum.toPrecision(3))}</span>
        </div>;
      }
      else if(sum === 1) {
        return <div className="timetrackingDayTotal brd-green-timetrackingDay">
          <span className="bg-green white center">{sum}</span>
        </div>;
      }
      else if(sum > 1) {
        return <div className="timetrackingDayTotal brd-orange-timetrackingDay">
          <span className="bg-orange white center">{parseFloat(sum.toPrecision(3))}</span>
        </div>;
      }
      else {
        return null;
      }
    }
  }

  footerTemplateWeekend(props) {
    if(props) {
      const sum = getObject('Custom', props);
      // const sum = getObject('Count', props);

      if(sum === 0) {
        return <div className=""></div>;
      }
      else if(sum > 0) {
        return <div className="timetrackingDayTotal brd-orange-timetrackingDay">
          <span className="bg-orange white center">{parseFloat(sum.toPrecision(3))}</span>
        </div>;
      }
      else {
        return null;
      }
    }
  }
  
  render() {
    let { language, itemId, itemType, currentView, dictParameters, dictColumns, columns, dataSource, info, informationMessage } = this.state;
    let height, minimum;

    if(Object.entries(dictParameters).length === 0 || columns.length === 0 || dataSource.length === 0) {
      return <div className=""></div>;
    }

    // Table Height
    if(window.innerHeight < 100) {
      height = '100px';
    }
    else {
      height = (window.innerHeight - 440);
    }
    // else {
    //   height = 'auto';
    // }

    // Column Menu
    this.columnMenu = [];
    // this.columnMenu = ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending', 'Filter'];

    // Context Menu
    this.contextMenuItems = [];

    // Edit Options
    this.editOptions = { allowAdding: false, allowDeleting: false, allowEditing: true, mode: 'Cell' };

    // Grid Lines
    this.gridLines = 'Both';

    // Validation Rules
    this.dateRules = { date: true };
    this.nameRules = { maxLength: [this.nameValidation, Traduction.translate(language, 'rules_name')], required: true };
    this.numberRules = { min: [this.numberValidation, Traduction.translate(language, 'rules_number')], number: true };

    return (
      <div className="timetrackingTable">
        {/* Message Information Popup */}
        {info && <PopupInformation Message={informationMessage} onMessageValidate={this.validateMessage}></PopupInformation>}
        
        {/* TreeGrid Component */}
        <div className="control-pane">
          <div className="control-section">
            {dataSource.length > 0 && 
              <TreeGridComponent id={'table' + currentView.ViewId} dataSource={dataSource} idMapping='Item_ID' parentIdMapping='Parent_ID' locale={Traduction.translate(language, 'locale')} height={height} editSettings={this.editOptions} gridLines={this.gridLines} searchSettings={this.getTreeSearchSettings(dictColumns, dictParameters['Columns'])} columnMenuItems={this.columnMenuItems} contextMenuItems={this.contextMenuItems} dataBound={this.dataBound} rowDataBound={this.rowDataBound} actionBegin={this.actionBegin} actionComplete={this.actionComplete} cellEdit={this.cellEdit} resizeStop={this.resizeStop} excelQueryCellInfo={this.excelQueryCellInfo} showColumnMenu={false} showColumnChooser={false} allowFiltering={false} allowPaging={false} enableInfiniteScrolling={false} enableVirtualization={false} pageSettings={{ pageSize: 50 }} allowExcelExport={false} allowReordering={true} allowResizing={true} allowSorting={false} allowTextWrap={true} ref={treegrid=>this.grid=treegrid}>
                {/* Columns */}
                <ColumnsDirective>
                  {/* Create Current view Columns */}
                  {dictParameters && dictParameters.Columns.map((column, index) => {
                    let col;

                    // Check if ColumnHeader is sent by backend
                    if(dictColumns[column]) {
                      col = this.createColumn(dictColumns[column], dictParameters['ColumnsWidth'], true);

                      return <ColumnDirective key={index} field={col.field} type={col.type} headerText={col.headerText} headerTemplate={col.headerTemplate} edit={col.edit} editType={col.editType} format={col.format} template={col.template} textAlign={col.textAlign} isPrimaryKey={col.isPrimaryKey} allowEditing={col.allowEditing} visible={true} validationRules={col.validationRules} width={col.width}></ColumnDirective>
                    }
                  })}
                  {/* Create Timetracking days */}
                  {columns && columns.map((column, index) => {
                    let col;

                    if(column.FieldType === 'OpenDay' || column.FieldType === 'BankHoliday' || column.FieldType === 'Weekend') {
                      if(dictColumns[column.FieldName]) {
                        col = this.createColumn(dictColumns[column.FieldName], dictParameters['ColumnsWidth'], true);

                        return <ColumnDirective key={index} field={col.field} type={col.type} headerText={col.headerText} headerTemplate={col.headerTemplate} edit={col.edit} editType={col.editType} format={col.format} template={col.template} textAlign={col.textAlign} isPrimaryKey={col.isPrimaryKey} allowEditing={col.allowEditing} visible={true} validationRules={col.validationRules} width={col.width}></ColumnDirective>
                      }
                    }
                  })}
                  {/* Mandatory Columns if not in Current view */}
                  {!dictParameters.Columns.find(column => column === 'Burned_Workload') && <ColumnDirective field='Burned_Workload' headerText={'Burned_Workload'} isPrimaryKey={false} allowEditing={false} visible={false}></ColumnDirective>}
                  {!dictParameters.Columns.find(column => column === 'Item_ID') && <ColumnDirective field='Item_ID' headerText={'Item_ID'} isPrimaryKey={true} allowEditing={false} visible={false}></ColumnDirective>}
                  {!dictParameters.Columns.find(column => column === 'Item_Type') && <ColumnDirective field='Item_Type' headerText={'Item_Type'} isPrimaryKey={false} allowEditing={false} visible={false}></ColumnDirective>}
                </ColumnsDirective>
                {/* Footer */}
                <AggregatesDirective>
                  <AggregateDirective showChildSummary={false}>
                    <AggregateColumnsDirective>
                      {/* Create Timetracking days */}
                      {columns && columns.map((column, index) => {
                        const date = new Date(column.FieldName);
                        const columnName = date.getDate();

                        if(column.FieldType === 'OpenDay') {
                          return <AggregateColumnDirective key={index} field={"field_" + columnName} columnName={"field_" + columnName} type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                        }
                        else if(column.FieldType === 'BankHoliday' || column.FieldType === 'Weekend') {
                          return <AggregateColumnDirective key={index} field={"field_" + columnName} columnName={"field_" + columnName} type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateWeekend}/>
                        }
                      })}

                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                      <AggregateColumnDirective field='Item_ID' columnName='Item_ID' type='Custom' customAggregate={this.customAggregate} footerTemplate={this.footerTemplateOpenDay}/>
                    </AggregateColumnsDirective>
                  </AggregateDirective>
                </AggregatesDirective>
                {/* Services */}
                <Inject services={[ ContextMenu, ColumnMenu, Edit, Filter, Freeze, Sort, Reorder, Resize, Aggregate ]}/>
              </TreeGridComponent>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default TableTimeTracking;